import { Heading } from 'site-react/components/typography';
import { Price, salesTax } from 'site-react/components/typography/Price';

import styles from './OfficeCardTotalCost.module.css';

const OfficeCardTotalCost = ({ currency, totalPrice }) => {
  return (
    <span className={styles.OfficeCardTotalCost}>
      <Heading type="title3">
        <b>
          <Price amount={totalPrice} currency={currency} /> / mo
        </b>
      </Heading>

      {salesTax[currency] && (
        <>
          &nbsp;{' '}
          <span className="u-typographyContent3">{salesTax[currency]}</span>
        </>
      )}
    </span>
  );
};

export default OfficeCardTotalCost;
