import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';

const Price = ({ amount, currency = 'GBP', precision = 0 }) => {
  const intl = useIntl();
  const isNumeric = !Number.isNaN(amount) && Number.isFinite(amount);

  return (
    isNumeric && (
      <>
        {intl.formatNumber(amount, {
          currency: currency,
          maximumFractionDigits: precision,
          style: 'currency',
        })}
      </>
    )
  );
};

Price.currencies = ['GBP'];

Price.propTypes = {
  /**
   * The amount to be displayed as a price.
   */
  amount: PropTypes.number.isRequired,

  /**
   * An ISO 4217 currency code.
   * https://en.wikipedia.org/wiki/ISO_4217#Active_codes
   */
  currency: PropTypes.oneOf(Price.currencies),

  /**
   * An integer representing the number of decimal places to display.
   */
  precision: PropTypes.number,
};

export default Price;
