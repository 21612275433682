import Router from 'next/router';
import Script from 'next/script';
import React, { useEffect } from 'react';

import '@material-design-icons/font/filled.css';
import 'site-react/theme/global.css';
import 'react-day-picker/dist/style.css';

import config from 'site-react/config';
import { AdvisorContextProvider } from 'site-react/data/core/AdvisorContext';
import { AlertContextProvider } from 'site-react/data/core/AlertContext';
import {
  CookiePreferencesContext,
  CookiePreferencesProvider,
} from 'site-react/data/core/CookiePreferencesContext';
import { FeatureFlagsProvider } from 'site-react/data/core/FeatureFlags';
import { I18nProvider } from 'site-react/data/core/I18n';
import { MembershipProvider } from 'site-react/data/core/MembershipContext';
import { PartTimeContextProvider } from 'site-react/data/core/PartTimeContext';
import { PersonaProvider } from 'site-react/data/core/PersonaContext';
import { ShortlistProvider } from 'site-react/data/core/ShortlistContext';
import { UserProvider } from 'site-react/data/core/UserContext';
import { UTMContextProvider } from 'site-react/data/core/UTMContext';
import useDetectExcessiveUrlChanges from 'site-react/hooks/useDetectExcessiveUrlChanges';

const MyApp = ({ Component, pageProps }) => {
  useEffect(() => {
    /**
     * Handle history.scrollRestoration manually when client-side routing
     *
     * Required because Next doesn’t handle automatic scrollRestoration correctly.
     * See: https://github.com/zeit/next.js/issues/3303. This thread highlights
     * various issues, and suggests potential causes and solutions. The most
     * deterministic solution is to switch to manual scrollRestoration when
     * client-side routing. This way the browser won’t try and interject with
     * unpredictable automatic behaviour, and edge-case can be addressed directly
     * in code.
     *
     * To ensure that history navigation is performant, you may also need to cache
     * data from getInitialProps. See search page for an example implementation.
     */
    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual';

      const cachedScrollPositions = [];
      let shouldScrollRestore;

      Router.events.on('routeChangeStart', () => {
        document.documentElement.classList.add('html--autoScroll');

        if (!shouldScrollRestore) {
          cachedScrollPositions.push([window.scrollX, window.scrollY]);
        }
      });

      Router.events.on('routeChangeComplete', () => {
        if (shouldScrollRestore) {
          const { x, y } = shouldScrollRestore;

          window.scrollTo(x, y);
          shouldScrollRestore = false;
        }

        document.documentElement.classList.remove('html--autoScroll');
        // NewModal prevent scroll class
        document.body.classList.remove('u-preventScroll');
      });

      Router.beforePopState(() => {
        if (cachedScrollPositions.length) {
          const [x, y] = cachedScrollPositions.pop();

          shouldScrollRestore = { x, y };
        }
        return true;
      });
    }

    return () => {
      if ('scrollRestoration' in window.history) {
        window.history.scrollRestoration = 'auto';
      }
    };
  });

  useDetectExcessiveUrlChanges();

  return (
    <AlertContextProvider>
      <FeatureFlagsProvider>
        <UserProvider>
          <I18nProvider>
            <CookiePreferencesProvider>
              <PersonaProvider>
                <ShortlistProvider>
                  <MembershipProvider>
                    <AdvisorContextProvider>
                      <UTMContextProvider>
                        <PartTimeContextProvider>
                          <CookiePreferencesContext.Consumer>
                            {({ isGtmLoadable }) => (
                              <>
                                <Component {...pageProps} />
                                {isGtmLoadable && (
                                  <Script src={config.GTM_URL} />
                                )}
                              </>
                            )}
                          </CookiePreferencesContext.Consumer>
                        </PartTimeContextProvider>
                      </UTMContextProvider>
                    </AdvisorContextProvider>
                  </MembershipProvider>
                </ShortlistProvider>
              </PersonaProvider>
            </CookiePreferencesProvider>
          </I18nProvider>
        </UserProvider>
      </FeatureFlagsProvider>
    </AlertContextProvider>
  );
};

export default MyApp;
