import React from 'react';

import VerticalSpacingProps from './VerticalSpacing.interface';
import styles from './VerticalSpacing.module.css';

export default function VerticalSpacing({
  children = null,
  size,
}: VerticalSpacingProps) {
  return <div className={styles[`VerticalSpacing--${size}`]}>{children}</div>;
}
