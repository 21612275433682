import { DateTime } from 'luxon';

/**
 * Verify the date was parsed correctly, so calls to methods like .toISODate()
 * have a consistent return type of `string` rather than `string|null`
 *
 * @param {DateTime} dateTime the DateTime to validate
 * @returns {DateTime}
 */
export default function ensureValidDateTime(
  dateTime: DateTime<true | false>,
): DateTime<true> {
  if (!dateTime.isValid) {
    throw new Error(
      `Invalid DateTime: ${dateTime.invalidReason}: ${dateTime.invalidExplanation}`,
    );
  }

  return dateTime;
}
