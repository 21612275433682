import { Button } from 'site-react/components/form';
import { LinkAsButton } from 'site-react/components/navigation';
import { Section } from 'site-react/components/page';
import { LazyModal } from 'site-react/components/page/ModalNew';
import usePartTime from 'site-react/hooks/usePartTime';
import {
  BuildingPropTypes,
  PricePlanPropTypes,
  UserPropTypes,
} from 'site-react/proptypes';

import PricePerMonth from './components/PricePerMonth';
import ViewOffices from './components/ViewOffices';
import styles from './ViewingRequestBanner.module.css';

export default function ViewingRequestBanner({ building, pricePlan, user }) {
  const { calculatePrice } = usePartTime();

  function getPriceAndDeskRange(building, pricePlan) {
    if (!pricePlan) {
      const { prices, desksAvailable } = building.pricePlans.reduce(
        (acc, pricePlan) => {
          const price =
            pricePlan.typeOfPricePlan === 'part-time-office'
              ? calculatePrice(pricePlan)
              : pricePlan.fullTimePrice;

          return {
            desksAvailable: [
              ...acc.desksAvailable,
              pricePlan.desksAvailable,
            ].sort((a, b) => a - b),
            prices: [...acc.prices, price].sort((a, b) => a - b),
          };
        },
        { desksAvailable: [], prices: [] },
      );

      return {
        desksAvailable: [
          desksAvailable[0],
          desksAvailable[desksAvailable.length - 1],
        ],
        lowestPrice: prices[0],
      };
    } else {
      const lowestPrice =
        pricePlan.typeOfPricePlan === 'part-time-office'
          ? calculatePrice(pricePlan)
          : pricePlan.fullTimePrice;

      return { desksAvailable: [pricePlan.desksAvailable], lowestPrice };
    }
  }

  const { lowestPrice, desksAvailable } = getPriceAndDeskRange(
    building,
    pricePlan,
  );

  return (
    <div className={styles.ViewingRequestBanner}>
      <Section>
        <div className={styles['ViewingRequestBanner-content']}>
          <div className={styles['ViewingRequestBanner-officeDetails']}>
            <ViewOffices
              fromDeskCount={desksAvailable[0]}
              suffix={!pricePlan ? ' from' : null}
              toDeskCount={desksAvailable[1]}
            />
            <PricePerMonth currency={building.currency} price={lowestPrice} />
          </div>
          <div className={styles['ViewingRequestBanner-actions']}>
            <LazyModal
              id="get-a-quote"
              label="Get a detailed quote"
              lazyComponent={async () =>
                pricePlan?.typeOfPricePlan === 'part-time-office'
                  ? (await import('../PartTimeQuoteModal')).default
                  : (await import('../FullTimeQuoteModal')).default
              }
              renderTrigger={({ isLoading, openModal }) => (
                <Button
                  disabled={isLoading}
                  isCompact
                  isLoading={isLoading}
                  name="Enquire"
                  onClick={openModal}
                  styleType="secondary"
                  type="button"
                >
                  Enquire
                </Button>
              )}
            >
              {(GetAQuoteModal) => (
                <GetAQuoteModal
                  buildingName={building.name}
                  location={building.areas.at(-1).name}
                  pricePlanId={pricePlan?.id}
                  pricePlanSize={pricePlan?.capacity}
                  userEmail={user?.email}
                />
              )}
            </LazyModal>

            <LinkAsButton href="#office-details" isCompact name="View Offices">
              View Offices
            </LinkAsButton>
          </div>
        </div>
      </Section>
    </div>
  );
}

ViewingRequestBanner.propTypes = {
  building: BuildingPropTypes.isRequired,
  pricePlan: PricePlanPropTypes,
  user: UserPropTypes,
};
