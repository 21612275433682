import Link from 'next/link';
import { usePathname } from 'next/navigation';

import { LinkAsButton } from 'site-react/components/navigation';
import { UICard } from 'site-react/components/page';
import { Heading, TextWithIcon } from 'site-react/components/typography';
import { Price, salesTax } from 'site-react/components/typography/Price';
import { VerticalSpacing } from 'site-react/components/utility';
import usePartTime from 'site-react/hooks/usePartTime';

import styles from './OfficeDetail.module.css';

export default function OfficeDetail({
  currency,
  otherOptionsCount,
  pricePlan,
}) {
  const {
    calculatePrice,
    partTimePreferences: { days },
  } = usePartTime();
  const pathname = usePathname();

  const access =
    pricePlan.typeOfPricePlan === 'part-time-office' ? 'partTime' : 'fullTime';

  const primaryPrice =
    access === 'partTime' ? calculatePrice(pricePlan) : pricePlan.price;

  let showSecondaryMatch, secondaryPrice, alsoAvailableText;
  if (access === 'fullTime' && pricePlan.isAvailablePartTime) {
    showSecondaryMatch = true;
    secondaryPrice = calculatePrice(pricePlan);
    alsoAvailableText = 'Also available part-time';
  }

  let viewSuffix;
  if (otherOptionsCount === 1) {
    viewSuffix = ' and 1 other option';
  } else if (otherOptionsCount > 1) {
    viewSuffix = ` and ${otherOptionsCount} other options`;
  }

  return (
    <>
      <UICard
        style={{
          borderRadius: 'var(--space-sm)',
          padding: 'var(--space-md)',
        }}
      >
        <Heading type="content3">
          <b>Closest match</b>
        </Heading>
        <VerticalSpacing size="xs" />

        <div className={styles['OfficeDetail-matchWrapper']}>
          <div>
            <span className={styles['OfficeDetail-desks']}>
              {pricePlan.desksAvailable} desks
              {access === 'partTime' ? ': ' : ''}
            </span>

            {access === 'partTime' ? (
              <span className={styles['OfficeDetail-days']}>
                {days
                  .map(
                    (day) =>
                      `${day.substr(0, 1).toUpperCase()}${day.substr(1, 1)}`,
                  )
                  .join(', ')}
              </span>
            ) : null}
          </div>

          <div>
            <span className={styles['OfficeDetail-price']}>
              <Price amount={primaryPrice} currency={currency} />
              /mo
            </span>
            {salesTax[currency] && (
              <>
                &nbsp;
                <span className="u-typographyContent3">
                  {salesTax[currency]}
                </span>
              </>
            )}
          </div>
        </div>

        {showSecondaryMatch ? (
          <div className={styles['OfficeDetail-matchWrapper']}>
            <div>
              <span className={styles['OfficeDetail-alsoAvailable']}>
                {alsoAvailableText}
              </span>
            </div>

            <div>
              <span className={styles['OfficeDetail-alsoAvailablePrice']}>
                from <Price amount={secondaryPrice} currency={currency} />
                /mo
              </span>
            </div>
          </div>
        ) : null}
        <VerticalSpacing size="xs" />
        <Link href="#office-details">View office details{viewSuffix}</Link>
      </UICard>
      <VerticalSpacing size="sm" />
      <LinkAsButton
        analyticsMetadata={{
          label: 'Book a viewing (Office Finder)',
        }}
        data-testid="BookAViewingButton"
        href={`${pathname}/book-viewing?option=${pricePlan.id}&access=${access}`}
        isBlock
        isCompact
        name="Book a viewing"
      >
        <TextWithIcon
          contentType="content2"
          iconName="bolt"
          iconPosition="left"
          iconSize="md"
          labelType="label1"
          text="Book a viewing"
        />
      </LinkAsButton>
    </>
  );
}
