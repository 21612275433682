import { DateTime } from 'luxon';

import ensureValidDateTime from 'site-react/helpers/ensureValidDateTime';

import getNextAvailableAppointment from './getNextAvailableAppointment';
import toBuildingTime from './toBuildingTime';

/**
 * Given a date (as an ISO string), returns an array of unavailable times.
 *
 * This is determined by:
 * 1. checking if the date we're looking at is in the future - if it is, all times
 *    are available, so we return an empty array
 * 2. if it is the current date, walks backward from now until open time and disables
 *    every half hour interval.
 *
 * N.B. for testing: depends on the current time to determine what future times are available.
 *
 * @param {string} date the date to check
 */
export default function getTimesForDay(
  date: string,
  {
    buildingTimezone = 'Europe/London',
    startTime = '8:30',
    endTime = '18:00',
    buildingOpenTime = startTime,
    buildingCloseTime = endTime,
  }: {
    buildingTimezone?: string;
    startTime?: string;
    endTime?: string;
    buildingOpenTime?: string;
    buildingCloseTime?: string;
  } = {},
) {
  const nowInBuildingLocalTime = ensureValidDateTime(
    toBuildingTime(buildingTimezone, DateTime.utc()),
  );

  const nextAppointmentISO = getNextAvailableAppointment(
    nowInBuildingLocalTime.toISO(),
    buildingTimezone,
    buildingOpenTime,
    buildingCloseTime,
  );

  // { setZone: true } ensures that the time values are in London time.
  // Normally, Luxon will try and convert to the user's native time.
  const nextAppointment = DateTime.fromISO(nextAppointmentISO, {
    zone: buildingTimezone,
  });

  const [startHour, startMinute] = startTime
    .split(':')
    .map((str) => parseInt(str, 10));
  const [endHour, endMinute] = endTime
    .split(':')
    .map((str) => parseInt(str, 10));
  const [buildingOpenHour, buildingOpenMinute] = buildingOpenTime
    .split(':')
    .map((str) => parseInt(str, 10));
  const [buildingCloseHour, buildingCloseMinute] = buildingCloseTime
    .split(':')
    .map((str) => parseInt(str, 10));

  const startDateTime = toBuildingTime(
    buildingTimezone,
    DateTime.fromISO(date).set({
      hour: startHour,
      minute: startMinute,
    }),
    true,
  );
  const endDateTime = toBuildingTime(
    buildingTimezone,
    DateTime.fromISO(date).set({
      hour: endHour,
      minute: endMinute,
    }),
    true,
  );

  const buildingOpenDateTime = toBuildingTime(
    buildingTimezone,
    DateTime.fromISO(date).set({
      hour: buildingOpenHour,
      minute: buildingOpenMinute,
    }),
    true,
  );
  const buildingCloseDateTime = toBuildingTime(
    buildingTimezone,
    DateTime.fromISO(date).set({
      hour: buildingCloseHour,
      minute: buildingCloseMinute,
    }),
    true,
  );

  let someDateTime =
    startDateTime < buildingOpenDateTime ? startDateTime : buildingOpenDateTime;

  const stopTime =
    endDateTime > buildingCloseDateTime ? endDateTime : buildingCloseDateTime;

  const times: { disabled: boolean; time: string }[] = [];
  while (someDateTime <= stopTime) {
    times.push({
      disabled:
        someDateTime < nextAppointment ||
        someDateTime < buildingOpenDateTime ||
        someDateTime > buildingCloseDateTime,
      time: someDateTime.toFormat('HH:mm'),
    });

    someDateTime = someDateTime.plus({ minute: 15 });
  }

  return times;
}
