import cn from 'classnames';

import { Grid } from 'site-react/components/page';
import {
  Badges,
  Heading,
  Paragraph,
  TextWithIcon,
} from 'site-react/components/typography';
import { HorizontalRule, VerticalSpacing } from 'site-react/components/utility';
import { OfficeCardRecommendationListButton } from 'site-react/features/Recommendations';
import useAdvisor from 'site-react/hooks/useAdvisor';

import PrivateOfficeCardShortlistButton from './PrivateOfficeCardShortlistButton';
import styles from './PrivateOfficeFullTimeCard.module.css';
import USPContent from './USPContent';
import OfficeCardAvailableFromLabel from '../OfficeCardAvailableFromLabel';
import OfficeCardButtonGroup from '../OfficeCardButtonGroup';
import OfficeCardCapacityHeading from '../OfficeCardCapacityHeading';
import OfficeCardFeaturesList from '../OfficeCardFeaturesList';
import OfficeCardHoppyLink from '../OfficeCardHoppyLink';
import OfficeCardStatsList from '../OfficeCardStatsList';
import OfficeCardTotalCost from '../OfficeCardTotalCost';

const PrivateOfficeFullTimeCard = ({
  availableFromText,
  buildingName,
  buildingType,
  capacity,
  cohort = 'control',
  currency,
  enableHoppyLink,
  floor,
  isHighlighted,
  location,
  maxMonthsTerm,
  minMonthsTerm,
  price,
  pricePlanId,
  renderedMarkdownPricePlanContent: { keyFeatures, notIncluded },
  sqft,
  specialOffer,
  userEmail,
}) => {
  const { recommendationList } = useAdvisor();

  const isCardExpandable = buildingType || keyFeatures || notIncluded;

  const WrapperElement = isCardExpandable ? 'details' : 'div';
  const ContentElement = isCardExpandable ? 'summary' : 'div';

  return (
    <div
      className={cn(styles['PrivateOfficeFullTimeCard'], {
        [styles['PrivateOfficeFullTimeCard--hasSpecialOffer']]: specialOffer,
        [styles['PrivateOfficeFullTimeCard--isHighlighted']]: isHighlighted,
      })}
    >
      <Grid>
        <Grid.Item colSpan="9">
          <div className={styles['PrivateOfficeFullTimeCard-left']}>
            <WrapperElement>
              <ContentElement
                className={styles['PrivateOfficeFullTimeCard-content']}
              >
                <div
                  className={cn(styles['PrivateOfficeFullTimeCard-badges'], {
                    [styles['PrivateOfficeFullTimeCard-withBadge']]:
                      buildingType,
                  })}
                >
                  {buildingType && (
                    <div
                      className={
                        styles['PrivateOfficeFullTimeCard-badgeWrapper']
                      }
                    >
                      <Badges.Badge
                        color={
                          buildingType.label === 'Offer'
                            ? 'brandprimary'
                            : 'white'
                        }
                        isBorderVariant
                        label={buildingType.label}
                      />
                    </div>
                  )}
                  <OfficeCardAvailableFromLabel
                    availableFromText={availableFromText}
                  />
                  {recommendationList ? (
                    <OfficeCardRecommendationListButton
                      pricePlanId={pricePlanId}
                    />
                  ) : null}
                  {enableHoppyLink ? (
                    <OfficeCardHoppyLink pricePlanId={pricePlanId} />
                  ) : null}

                  <PrivateOfficeCardShortlistButton
                    isPartTime={false}
                    pricePlanId={pricePlanId}
                  />
                </div>
                <VerticalSpacing size="sm" />
                <OfficeCardCapacityHeading capacity={capacity} />
                <VerticalSpacing size="sm" />
                <OfficeCardStatsList
                  floor={floor}
                  maxMonthsTerm={maxMonthsTerm}
                  minMonthsTerm={minMonthsTerm}
                  sqft={sqft}
                />
                <VerticalSpacing size="sm" />
                <div className={styles['horizontalRule-hideOnMobile']}>
                  <HorizontalRule />
                </div>
                <VerticalSpacing size="md" />
                {isCardExpandable && (
                  <span
                    className={styles['PrivateOfficeFullTimeCard-expandIcon']}
                  >
                    <u>See office details</u>
                  </span>
                )}
              </ContentElement>
              {buildingType && (
                <>
                  <VerticalSpacing size="md" />
                  <Heading type="content1">
                    <b>{buildingType.label}</b>
                  </Heading>
                  <VerticalSpacing size="sm" />
                  <Paragraph isMarginless>
                    {buildingType.longDescription}
                  </Paragraph>
                  <VerticalSpacing size="md" />
                </>
              )}
              <OfficeCardFeaturesList
                featuresExcluded={notIncluded}
                featuresIncluded={keyFeatures}
              />
            </WrapperElement>

            {specialOffer && (
              <>
                <VerticalSpacing size="md" />
                <div
                  className={cn(
                    styles['PrivateOfficeFullTimeCard-specialOffer'],
                    styles['u-displayAboveMd'],
                  )}
                >
                  <TextWithIcon
                    contentType="content3"
                    iconName="local_offer"
                    iconPosition="left"
                    text={<b>{specialOffer}</b>}
                  />
                </div>
              </>
            )}
          </div>
        </Grid.Item>
        <Grid.Item colSpan="3" colStart="10">
          <div className={styles['PrivateOfficeFullTimeCard-right']}>
            <div className={styles['PrivateOfficeFullTimeCard-pricing']}>
              <Badges.Badge color="brandsecondary" label="Full Time" />
              <VerticalSpacing size="md" />
              <OfficeCardTotalCost currency={currency} totalPrice={price} />
            </div>
            <VerticalSpacing size="md" />

            <OfficeCardButtonGroup
              buildingName={buildingName}
              cohort={cohort}
              location={location}
              officeAccess="fullTime"
              price={price}
              pricePlanId={pricePlanId}
              pricePlanSize={capacity}
              userEmail={userEmail}
              uspContent={<USPContent />}
            />
          </div>
        </Grid.Item>
      </Grid>
      {specialOffer && (
        <div
          className={cn(
            styles['PrivateOfficeFullTimeCard-specialOffer'],
            'u-displayBelowMd',
          )}
        >
          <TextWithIcon
            contentType="content3"
            iconName="local_offer"
            iconPosition="left"
            text={<b>{specialOffer}</b>}
          />
        </div>
      )}
    </div>
  );
};

export default PrivateOfficeFullTimeCard;
