import cn from 'classnames';
import { DateTime } from 'luxon';
import React, { useContext } from 'react';

import { MaterialIcon } from 'site-react/components/typography';
import { Price, salesTax } from 'site-react/components/typography/Price';
import {
  ViewingRequestContext,
  OfficeAccessOptions,
} from 'site-react/data/listing/ViewingRequestContext';

import styles from './DisplayComponent.module.css';

const DisplayComponent = ({ currency, selectedPricePlan }) => {
  const { officeAccess } = useContext(ViewingRequestContext);

  const dropDownPriceDisplay = () => {
    if (officeAccess === OfficeAccessOptions.PartTime) {
      return <span>Part-time Private Office</span>;
    } else {
      return (
        <>
          <span>Private Office</span>
          <span>&nbsp;-&nbsp;</span>
          <span>
            <Price amount={selectedPricePlan.price} currency={currency} /> / mo
            {salesTax[currency] && (
              <>
                &nbsp;
                <span className="u-typographyContent3">
                  ({salesTax[currency]})
                </span>
              </>
            )}
          </span>
        </>
      );
    }
  };

  const getTitleCopy = () => {
    let titleCopy = `~${selectedPricePlan.capacity} people`;
    if (selectedPricePlan?.sqft > 0) {
      titleCopy += `, ${selectedPricePlan.sqft} sqft`;
    }

    return titleCopy;
  };

  let availableFromText = 'Available now';
  let parsedAvailableFromDate = '';

  if (selectedPricePlan?.availableFrom) {
    parsedAvailableFromDate = DateTime.fromFormat(
      selectedPricePlan.availableFrom,
      'yyyy-MM-dd',
    );
  }

  const isAvailableNow = !(
    selectedPricePlan?.availableFrom &&
    parsedAvailableFromDate > DateTime.local()
  );
  if (!isAvailableNow) {
    availableFromText = `Available ${parsedAvailableFromDate.toFormat(
      'dd MMM yy',
    )}`;
  }

  return (
    <div className={styles['DisplayComponent']}>
      {selectedPricePlan ? (
        <span className={styles['DisplayComponent-displayContent']}>
          {dropDownPriceDisplay(selectedPricePlan)}
        </span>
      ) : (
        <span className={styles['DisplayComponent-dropdownTitle']}>-</span>
      )}
      {selectedPricePlan ? (
        <>
          <span className={styles['DisplayComponent-capacity']}>
            {getTitleCopy()}
          </span>
          <span
            className={cn(
              'u-typographySmallLabel',
              styles['DisplayComponent-availableFrom'],
              {
                [styles['DisplayComponent-availableFrom--now']]: isAvailableNow,
              },
            )}
          >
            {availableFromText}
          </span>
        </>
      ) : null}
      <MaterialIcon
        className={styles['DisplayComponent-carat']}
        iconType="arrow_drop_down"
      />
    </div>
  );
};

export default DisplayComponent;
