import PropTypes from 'prop-types';

import CoworkingProductPropTypes from './CoworkingProduct.proptypes';
import MeetingRoomProductPropTypes from './MeetingRoomProduct.proptypes';
import PrivateOfficeProductPropTypes from './PrivateOfficeProduct.proptypes';

export default PropTypes.exact({
  address: PropTypes.string,
  areas: PropTypes.arrayOf(
    PropTypes.exact({
      areaType: PropTypes.string,
      id: PropTypes.number,
      isAnchor: PropTypes.bool,
      iso: PropTypes.string,
      name: PropTypes.string,
      slug: PropTypes.string,
    }),
  ),
  buildingType: PropTypes.exact({
    desc: PropTypes.string,
    key: PropTypes.string,
    label: PropTypes.string,
    longDescription: PropTypes.string,
  }),
  closed: PropTypes.array,
  currency: PropTypes.string.isRequired,
  facilities: PropTypes.arrayOf(PropTypes.string),
  freeTrialPassUseCap: PropTypes.number,
  hostId: PropTypes.number,
  howToFindUs: PropTypes.string,
  id: PropTypes.number,
  images: PropTypes.arrayOf(
    PropTypes.exact({
      caption: PropTypes.string,
      id: PropTypes.number,
      img: PropTypes.string,
      index: PropTypes.number,
      path: PropTypes.string,
      seatingConfig: PropTypes.string,
    }),
  ),
  isAvailableOnHq: PropTypes.bool,
  isAvailableOnPass: PropTypes.bool,
  isAvailablePartTime: PropTypes.bool,
  isBrand: PropTypes.bool,
  landmarks: PropTypes.arrayOf(
    PropTypes.exact({
      distance: PropTypes.number,
      index: PropTypes.number,
      landmark: PropTypes.exact({
        area: PropTypes.exact({
          name: PropTypes.string,
          slug: PropTypes.string,
        }),
        images: PropTypes.arrayOf(
          PropTypes.exact({
            description: PropTypes.string,
            img: PropTypes.string,
            index: PropTypes.number,
          }),
        ),
        isSearchArea: PropTypes.bool,
        kind: PropTypes.string,
        lat: PropTypes.number,
        lng: PropTypes.number,
        name: PropTypes.string,
        slug: PropTypes.string,
        subType: PropTypes.string,
      }),
    }),
  ),
  location: PropTypes.exact({
    coordinates: PropTypes.array,
    type: PropTypes.string,
  }),
  minMonthsTerm: PropTypes.number,
  name: PropTypes.string,
  offices: PropTypes.arrayOf(
    PropTypes.exact({
      archivedAt: PropTypes.string,
      availableFrom: PropTypes.string,
      building: PropTypes.number,
      buildingDetail: PropTypes.exact({
        address: PropTypes.string,
        archivedAt: PropTypes.string,
        buildingType: PropTypes.string,
        createdAt: PropTypes.string,
        deletedAt: PropTypes.string,
        hostId: PropTypes.number,
        howToFindUs: PropTypes.string,
        id: PropTypes.number,
        isAvailableFullTime: PropTypes.bool,
        isAvailablePartTime: PropTypes.bool,
        isHidden: PropTypes.bool,
        lat: PropTypes.number,
        lng: PropTypes.number,
        name: PropTypes.string,
        overview: PropTypes.string,
        parentArea: PropTypes.number,
        postcode: PropTypes.string,
        publishedAt: PropTypes.string,
        slug: PropTypes.string,
        specialOffer: PropTypes.string,
        status: PropTypes.string,
        updatedAt: PropTypes.string,
        vatRegistered: PropTypes.bool,
        viewingsEndTime: PropTypes.string,
        viewingsStartTime: PropTypes.string,
      }),
      capacity: PropTypes.number,
      createdAt: PropTypes.string,
      deletedAt: PropTypes.string,
      desksAvailable: PropTypes.number,
      floor: PropTypes.arrayOf(PropTypes.string),
      formattedPrice: PropTypes.string,
      hoursPerWeek: PropTypes.number,
      id: PropTypes.number,
      isAvailablePartTime: PropTypes.bool,
      keyFeatures: PropTypes.string,
      maxMonthsTerm: PropTypes.PropTypes.number,
      minMonthsTerm: PropTypes.PropTypes.number,
      notIncluded: PropTypes.string,
      partTimePriceOffPeak: PropTypes.number,
      partTimePricePeak: PropTypes.number,
      price: PropTypes.number,
      priceAutoUpdated: PropTypes.bool,
      pricePerPerson: PropTypes.number,
      publishedAt: PropTypes.string,
      ranking: PropTypes.number,
      reasonUnpublished: PropTypes.string,
      seatingConfig: PropTypes.string,
      sqft: PropTypes.number,
      status: PropTypes.string,
      subletAvailableDays: PropTypes.array,
      updatedAt: PropTypes.string,
      url: PropTypes.string,
    }),
  ),
  onDemandProduct: PropTypes.exact({
    buildingClosingTime: PropTypes.string,
    coworkingBookingCutoff: PropTypes.objectOf(PropTypes.string),
    extraPassFacilities: PropTypes.array,
    includedPassFacilities: PropTypes.arrayOf(PropTypes.string),
    isPromoted: PropTypes.bool,
  }),
  passCapacityLimit: PropTypes.number,
  passPrice: PropTypes.number,
  passTeammates: PropTypes.array,
  postcode: PropTypes.string,
  products: PropTypes.arrayOf(
    PropTypes.oneOfType([
      CoworkingProductPropTypes,
      MeetingRoomProductPropTypes,
      PrivateOfficeProductPropTypes,
    ]),
  ),
  score: PropTypes.number,
  slug: PropTypes.string,
  specialOffer: PropTypes.string,
  status: PropTypes.oneOf([
    'deleted',
    'archived',
    'draft',
    'hubble-review',
    'host-review',
    'not-set',
    'published',
  ]).isRequired,
  timeZone: PropTypes.string,
  vrTourUrl: PropTypes.string,
});
