import cn from 'classnames';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';

import {
  BackButton,
  Breadcrumbs,
  PageNavigation,
} from 'site-react/components/navigation';
import { Section } from 'site-react/components/page';
import ModalNew from 'site-react/components/page/ModalNew';
import { Heading } from 'site-react/components/typography';
import { VerticalSpacing } from 'site-react/components/utility';
import useFeatureFlags from 'site-react/hooks/useFeatureFlags';
import useUser from 'site-react/hooks/useUser';

import ArchivedListingRedirectModal from './components/ArchivedListingRedirectModal';
import Facilities from './components/Facilities';
import Hero from './components/Hero';
import Highlights from './components/Highlights';
import Location from './components/Location';
import Offices from './components/Offices';
import PassProductOffering from './components/PassProductOffering';
import Summary from './components/Summary';
import ViewingRequestBanner from './components/ViewingRequestBanner';
import getBreadcrumbs from './helpers/getBreadcrumbs';
import usePricePlanFilters from './hooks/usePricePlanFilters';
import styles from './HqListing.module.css';
import { HQRenderedMarkdownContentPropTypes } from './proptypes';

const mapFacilities = (facilitySlugs = [], facilitiesList) => {
  let availableFacilities = [];
  let unavailableFacilities = [];

  facilitiesList.forEach((facility) => {
    if (facilitySlugs.indexOf(facility.slug) > -1) {
      availableFacilities.push(facility);
    } else {
      unavailableFacilities.push(facility);
    }
  });

  return { availableFacilities, unavailableFacilities };
};

export default function HqListing({
  building,
  cohort = 'control',
  facilitiesList,
  navItems = [],
  officeSelectorABCohort,
  renderedMarkdownContent,
}) {
  const { user } = useUser();
  const { enableHQListingStickyMobileCTA } = useFeatureFlags();
  const router = useRouter();

  const { dispatchSearchState, matches, other, searchState, submitParams } =
    usePricePlanFilters({ pricePlans: building.pricePlans });

  const breadcrumbProps = getBreadcrumbs({ building });

  const { highlights, overview, tags } = building;
  const [facilities] = useState(
    mapFacilities(building.facilities, facilitiesList),
  );

  const previousSearchPage = useMemo(() => {
    if (typeof window === 'undefined') {
      return null;
    }

    const previousSearchPage = sessionStorage.getItem('previousSearchPage');
    // We remove this immediately as keeping it could cause issues with other open listing pages.
    if (previousSearchPage) {
      sessionStorage.removeItem('previousSearchPage');
    }
    return previousSearchPage;
  }, []);

  function goBackToSearch() {
    if (previousSearchPage) {
      router.push(previousSearchPage);
    } else {
      const parentArea =
        breadcrumbProps.links[breadcrumbProps.links.length - 2];
      router.push(parentArea.url);
    }
  }

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (building.status === 'archived') {
        setIsModalOpen(true);
      }
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ModalNew
        id="archived-listing-redirect"
        isOpenOnRender={isModalOpen}
        label="Archived listing"
        onCloseCallback={() => {
          setIsModalOpen(false);
        }}
        renderTrigger={() => {}}
      >
        <ArchivedListingRedirectModal />
      </ModalNew>
      <PageNavigation
        ariaLabel="Listing Details"
        isScrollTracked
        navItems={navItems}
      />

      <span className={styles['HqListing-section']} id="image-gallery" />
      <Section id="image-gallery-container" verticalPadding="lg">
        <BackButton
          contentType="content1"
          data-analytics-tag="back_to_search"
          label="Back to search results"
          onBackButtonClick={goBackToSearch}
        />
        <VerticalSpacing size="sm" />
        <Heading level="1" type="title3">
          <span className={styles['HqListing-buildingNameHeading']}>
            {building.name}
          </span>
        </Heading>
        <VerticalSpacing size="sm" />
        <Breadcrumbs {...breadcrumbProps} />
        <VerticalSpacing size="sm" />
      </Section>

      <div className={styles['HqListing-imageCarousel']}>
        <Hero
          building={building}
          cohort={cohort}
          dispatchSearchState={dispatchSearchState}
          matches={matches}
          officeSelectorABCohort={officeSelectorABCohort}
          searchState={searchState}
          submitParams={submitParams}
        />
      </div>

      <VerticalSpacing size="md" />

      {enableHQListingStickyMobileCTA && building.status === 'published' && (
        <div
          className={cn(
            'u-displayLgNone',
            styles['HqListing-viewingRequestBanner'],
          )}
        >
          <ViewingRequestBanner
            building={building}
            pricePlan={matches[0]}
            user={user}
          />
        </div>
      )}

      {building.pricePlans.length ? (
        <>
          <span className={styles['HqListing-section']} id="office-details" />
          <Section id="office-details-container" verticalPadding="md">
            <Offices
              buildingName={building.name}
              buildingType={building.buildingType}
              cohort={cohort}
              currency={building.currency}
              dispatchSearchState={dispatchSearchState}
              location={building.areas.at(-1).name}
              matches={matches}
              other={other}
              partTimeProductSettings={building.partTimeProductSettings}
              pricePlans={building.pricePlans}
              renderedMarkdownContent={renderedMarkdownContent}
              searchState={searchState}
              specialOffer={building.specialOffer}
              submitParams={submitParams}
            />
          </Section>
        </>
      ) : null}

      <span className={styles['HqListing-section']} id="location" />
      <Section id="location-container" verticalPadding="md">
        <Location
          buildingName={building.name}
          landmarks={building.landmarks}
          lat={building.lat}
          lng={building.lng}
        />
      </Section>

      <span className={styles['HqListing-section']} id="facilities" />
      <Section id="facilities-container" verticalPadding="md">
        <Facilities
          availableFacilities={facilities.availableFacilities}
          costPerHour={building.costPerHour}
          currency={building.currency}
          meetingRooms={building.numberOfMeetingRooms}
          unavailableFacilities={facilities.unavailableFacilities}
          usesCreditSystem={building.usesCreditSystem}
        />
      </Section>

      {highlights.length ? (
        <>
          <span className={styles['HqListing-section']} id="highlights" />
          <Section id="highlights-container" verticalPadding="md">
            <Highlights highlights={highlights} />
          </Section>
        </>
      ) : null}

      <span className={styles['HqListing-section']} id="about" />
      <Section id="about-container" verticalPadding="md">
        <Summary
          hashtags={tags}
          overview={overview}
          renderedMarkdownContent={renderedMarkdownContent}
        />
      </Section>

      {building.isAvailableOnPass ? (
        <Section verticalPadding="md">
          <PassProductOffering
            buildingId={building.id}
            buildingSlug={building.slug}
          />
        </Section>
      ) : null}

      <VerticalSpacing size="xxl" />
    </>
  );
}

HqListing.propTypes = {
  /**
   * a Building object
   */
  building: PropTypes.shape({
    buildingType: PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      longDescription: PropTypes.string.isRequired,
    }),
    city: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
    id: PropTypes.number.isRequired,
    landmarks: PropTypes.arrayOf(
      PropTypes.shape({
        distance: PropTypes.number.isRequired,
        landmark: PropTypes.shape({
          images: PropTypes.arrayOf(
            PropTypes.shape({
              img: PropTypes.string.isRequired,
            }),
          ),
          name: PropTypes.string.isRequired,
        }).isRequired,
      }),
    ),
    lat: PropTypes.number,
    lng: PropTypes.number,
    name: PropTypes.string.isRequired,
    postcode: PropTypes.string.isRequired,
    specialOffer: PropTypes.string,
  }).isRequired,

  facilitiesList: PropTypes.arrayOf(
    PropTypes.shape({
      group: PropTypes.string,
      icon: PropTypes.string,
      id: PropTypes.number,
      isAvailableOnHq: PropTypes.bool,
      isAvailableOnPass: PropTypes.bool,
      name: PropTypes.string,
      order: PropTypes.number,
      slug: PropTypes.string,
    }),
  ),
  navItems: PropTypes.arrayOf(PropTypes.shape({})),
  officeSelectorABCohort: PropTypes.oneOf(['control', 'variant']).isRequired,
  renderedMarkdownContent: HQRenderedMarkdownContentPropTypes.isRequired,
};
