import Image from 'next/image';
import { useMemo } from 'react';

import { Link } from 'site-react/components/navigation';
import { UICard } from 'site-react/components/page';
import { Heading } from 'site-react/components/typography';
import { Price, salesTax } from 'site-react/components/typography/Price';
import { OfficeAccessOptions } from 'site-react/data/listing/ViewingRequestContext';
import getImgixPathWithParameters from 'site-react/helpers/getImgixPathWithParameters';
import imgixLoader from 'site-react/helpers/imgixLoader';
import usePartTime from 'site-react/hooks/usePartTime';

import styles from './ViewingRequestOverview.module.css';

const ViewingRequestOverviewAccessType = ({ access, days }) => {
  const orderedDays = useMemo(() => {
    const allDays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'];
    return allDays.filter((day) => days.includes(day));
  }, [days]);

  if (access === 'partTime') {
    return (
      <span className={styles['ViewingRequestOverviewAccessType']}>
        <span>{days.length} days/week</span>
        {orderedDays.map((day) => (
          <abbr
            className={styles['ViewingRequestOverviewAccessType-day']}
            key={day}
            title={day}
          >
            {day.slice(0, 2)}
          </abbr>
        ))}
      </span>
    );
  }

  return (
    <span className={styles['ViewingRequestOverviewAccessType']}>
      Full time
    </span>
  );
};

const ViewingRequestOverview = ({
  access,
  buildingImage,
  buildingName,
  cohort = 'control',
  currency,
  listingPageHref,
  pricePlan,
}) => {
  const {
    calculatePrice,
    partTimePreferences: { days },
  } = usePartTime();

  const partTimePrice = Math.round(
    calculatePrice({
      partTimePriceOffPeak: pricePlan.partTimePriceOffPeak,
      partTimePricePeak: pricePlan.partTimePricePeak,
    }),
  );

  return (
    <UICard
      style={{
        border: `1px solid var(--color-neutral-500)`,
        borderRadius: 'var(--space-lg)',
        padding: `0`,
      }}
    >
      <div className={styles['ViewingRequestOverview']}>
        <div className={styles['ViewingRequestOverview-headingImageWrapper']}>
          <Heading type="title4">
            <Link href={listingPageHref} styleType="secondary">
              {buildingName}
            </Link>
          </Heading>

          {cohort === 'control' && (
            <div
              className={styles['ViewingRequestOverview-buildingImageWrapper']}
            >
              <Image
                alt="Building"
                className={styles['ViewingRequestOverview-buildingImageSmall']}
                height={72}
                loader={imgixLoader}
                src={getImgixPathWithParameters(buildingImage.img, {
                  fit: 'crop',
                  h: 72,
                  w: 80,
                })}
                width={80}
              />
              <Image
                alt="Building"
                className={styles['ViewingRequestOverview-buildingImageLarge']}
                height={186}
                loader={imgixLoader}
                src={getImgixPathWithParameters(buildingImage.img, {
                  fit: 'crop',
                  h: 186,
                  w: 398,
                })}
                style={{
                  objectFit: 'cover',
                  width: '100%',
                }}
                width={398}
              />
            </div>
          )}
        </div>
        <div className={styles['ViewingRequestOverview-pricePlan']}>
          {pricePlan.capacity > 1 && 'Up to'} {pricePlan.capacity}{' '}
          {pricePlan.capacity > 1 ? 'people' : 'person'}{' '}
          <div className={styles['ViewingRequestOverview-pricePlanDot']} />{' '}
          <ViewingRequestOverviewAccessType access={access} days={days} />
        </div>

        <div className={styles['ViewingRequestOverview-price']}>
          <Heading type="title4"> Monthly Price </Heading>
          <Heading type="title4">
            <Price
              amount={
                access === OfficeAccessOptions.PartTime
                  ? partTimePrice
                  : pricePlan.price
              }
              currency={currency}
            />
            {salesTax[currency] && (
              <>
                {' '}
                <span className={styles['ViewingRequestOverview-priceVAT']}>
                  {salesTax[currency]}
                </span>
              </>
            )}
          </Heading>
        </div>
      </div>
    </UICard>
  );
};

export default ViewingRequestOverview;
