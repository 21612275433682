import {
  MarkerClusterer,
  SuperClusterAlgorithm,
} from '@googlemaps/markerclusterer';
import debounce from 'lodash.debounce';
import { useEffect, useState } from 'react';

import analytics from 'site-react/helpers/Analytics';

import addMarkerListeners from './addMarkerListeners';
import allIconSets from '../../helpers/markerIcons';

const smallClusterImg = 'https://hubble.imgix.net/map/markers/v2/cluster/1.svg';
const smallClusterHoverImg =
  'https://hubble.imgix.net/map/markers/v2/cluster/1--hover.svg';

const largeClusterImg = 'https://hubble.imgix.net/map/markers/v2/cluster/3.svg';
const largeClusterHoverImg =
  'https://hubble.imgix.net/map/markers/v2/cluster/3--hover.svg';

/**
 * Determine the icon to use based on whether it is active or highlighted,
 * and whether it has been favourited.
 *
 * @param {Boolean} isActive - true if this is the actively selected object.
 * @param {Boolean} isHighlighted - true if the corresponding listing card is currently highlighted.
 */
const chooseIcon = (isActive, isHighlighted, icons) => {
  if (isActive) {
    return icons.activeIcon;
  }
  if (isHighlighted) {
    return icons.highlightedIcon;
  }
  return icons.defaultIcon;
};

/**
 * @typedef Location
 * @param {Array<Number>} coordinates - the point coordinates of the result.
 */

/**
 * @typedef Result
 * @param {Location} location - the location attributes of the result.
 * @param {Number} id - the id of the result (could be priceplan OR listing id.)
 */

/**
 * Hook for plotting markers for each of our results on a map.
 *
 * @param {Number} activeId - the id of the active selected priceplan (could be triggered by click).
 * @param {Array<Number>} favouriteIds - the ids of the favourited listings.
 * @param {Object} GoogleMap - the google maps api.
 * @param {Number} highlightedId - the id of the currently highlighted listing
 * @param {Object} map - our map, created using the google maps api.
 * @param {Array<Result>} results - the array of results pulled down from the search service.
 * @param {Function} setActiveId - callback for changing the id of the selected priceplan.
 * @param {Function} setIsFittingBounds - callback to inform if fitBounds method is active
 * @param {Function} setToggleStatus - callback to control the 'Search As I Move' functionality of the map
 * @param {Boolean} zoomToAreaOutline - is the map zoom controlled by an area outline
 *
 */
const usePlotMarkers = ({
  activeId,
  favouriteIds,
  GoogleMap,
  highlightedId,
  map,
  results,
  setActiveId,
  setIsFittingBounds,
  setToggleStatus,
  zoomToAreaOutline,
}) => {
  const [markers, setMarkers] = useState([]);
  const [markerClusterer, setMarkerClusterer] = useState();

  useEffect(() => {
    if (!(GoogleMap && map && results.length)) {
      return () => {};
    }

    if (GoogleMap && map && !markerClusterer) {
      const algorithm = new SuperClusterAlgorithm({
        maxZoom: 15, // How far zoomed in do we stop clustering
        minPoints: 4, // Minimum amount of listings before we form a cluster
        radius: 125, // Area of map (in pixels) forming a single cluster
      });

      const clusterSizes = {
        large: 'large',
        small: 'small',
      };

      const smallClusterIcon = {
        height: 64,
        url: smallClusterImg,
        width: 64,
      };

      const largeClusterIcon = {
        height: 68,
        url: largeClusterImg,
        width: 68,
      };

      const hoveredSmallCluster = {
        ...smallClusterIcon,
        url: smallClusterHoverImg,
      };

      const hoveredLargeCluster = {
        ...largeClusterIcon,
        url: largeClusterHoverImg,
      };

      const renderer = {
        render({ count, position }, stats) {
          const clusterSize =
            count > Math.max(10, stats.clusters.markers.mean)
              ? clusterSizes.large
              : clusterSizes.small;

          const icon =
            clusterSize === clusterSizes.large
              ? largeClusterIcon
              : smallClusterIcon;

          const marker = new GoogleMap.Marker({
            icon: icon,
            label: { color: 'white', fontSize: '11px', text: String(count) },
            minimumClusterSize: 4,
            position,
            type: clusterSize,
          });

          marker.addListener('mouseover', () => {
            marker.type === clusterSizes.large
              ? marker.setIcon(hoveredLargeCluster)
              : marker.setIcon(hoveredSmallCluster);
          });

          marker.addListener('mouseout', () => {
            marker.type === clusterSizes.large
              ? marker.setIcon(largeClusterIcon)
              : marker.setIcon(smallClusterIcon);
          });

          return marker;
        },
      };

      setMarkerClusterer(
        new MarkerClusterer({
          algorithm,
          map,
          markers,
          renderer,
        }),
      );
    }
  }, [GoogleMap, map, markers, markerClusterer, results, setMarkerClusterer]);

  useEffect(() => {
    if (markerClusterer) {
      markerClusterer.clearMarkers();
    }

    if (markers.length) {
      return setMarkers([]);
    }

    if (!(GoogleMap && map && results.length && markerClusterer)) {
      return () => {};
    }

    const bounds = new GoogleMap.LatLngBounds();

    results.forEach((result) => {
      const [lng, lat] = result.buildingLocation.coordinates;

      const marker = new GoogleMap.Marker({
        map,
        position: new GoogleMap.LatLng(lat, lng),
      });

      // Extend bounds to encapsulate each marker
      bounds.extend(marker.getPosition());

      marker.result = result;
      markers.push(marker);
      setMarkers(markers);
    });

    let timer;
    if (!zoomToAreaOutline) {
      timer = setTimeout(() => {
        setIsFittingBounds(true);
        // fit new bounds, but don't zoom all the way in (e.g. to a single marker)
        map.setOptions({ maxZoom: 18 });
        map.fitBounds(bounds);
      });
      GoogleMap.event.addListenerOnce(
        map,
        'idle',
        debounce(() => {
          // restore full zoom control to the user
          map.setOptions({ maxZoom: undefined });
          setIsFittingBounds(false);
        }, 200),
      );
    }

    if (markers.length && markerClusterer) {
      markerClusterer.addMarkers(markers);
      markerClusterer.addListener('click', () => {
        // Prevent map triggering new search when cluster zooms onClick
        setToggleStatus('inactive');
        analytics.track('Map Cluster Clicked');
      });
    }

    // Cleanup event called when the results are updated.
    return () => {
      markers.forEach((marker) => marker.setMap(null));
      clearTimeout(timer);
    };
  }, [
    GoogleMap,
    map,
    markerClusterer,
    markers,
    results,
    setIsFittingBounds,
    setToggleStatus,
    zoomToAreaOutline,
  ]);

  useEffect(() => {
    if (!markerClusterer) {
      return () => {};
    }
    markers.forEach((marker) => {
      const { result } = marker;
      const { buildingId } = result;
      const isActive = buildingId === activeId;
      const isFavourited = favouriteIds.includes(buildingId);
      const isHighlighted = parseInt(buildingId, 10) === highlightedId;

      // use a different set of icons if the listing/priceplan has been favourited.
      const iconSet = isFavourited
        ? allIconSets.favourited
        : allIconSets.standard;

      // We set the listeners separate to plotting the initial markers, because
      // the user's favourites could change without the results changing, which would
      // result in the iconSet changing when this useEffect is re-rendered.
      addMarkerListeners({
        GoogleMap,
        iconSet,
        isActive,
        map,
        marker,
        setActiveId,
      });

      // We set the icon here using marker.setIcon, not when the marker is instantiated, to prevent all markers
      // from rerendering in the useEffect for plotting the results above.
      const icon = chooseIcon(isActive, isHighlighted, iconSet);
      marker.setIcon(icon);
    });
    // Cleanup the old listeners when the dependencies change.
    return () =>
      markers.forEach((marker) => {
        GoogleMap.event.clearInstanceListeners(marker);
      });
  }, [
    activeId,
    favouriteIds,
    GoogleMap,
    highlightedId,
    map,
    markerClusterer,
    markers,
    results,
    setActiveId,
    setIsFittingBounds,
  ]);
};
export { chooseIcon };
export default usePlotMarkers;
